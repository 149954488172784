import { useEffect, DependencyList } from 'react'

export function useDebounceEffect(
	fn,
	waitTime,
	DependencyList,
) {
	useEffect(() => {
		const t = setTimeout(() => {
			fn.apply(undefined, DependencyList)
		}, waitTime)

		return () => {
			clearTimeout(t)
		}
	}, DependencyList)
}
