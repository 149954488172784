// extracted by mini-css-extract-plugin
export var account = "styles-module--account--22c6d";
export var add = "styles-module--add--eb4ac";
export var addNew = "styles-module--addNew--d658d";
export var clickBtn = "styles-module--clickBtn--a9459";
export var contactInfo = "styles-module--contactInfo--2635c";
export var dFlex = "styles-module--dFlex--32fbb";
export var flexColumn = "styles-module--flexColumn--f0f65";
export var flipCard = "styles-module--flipCard--46de1";
export var flipCardBack = "styles-module--flipCardBack--7fd73";
export var flipCardFront = "styles-module--flipCardFront--f64d6";
export var flipCardInner = "styles-module--flipCardInner--cf6cc";
export var genderText = "styles-module--genderText--0205b";
export var nameTag = "styles-module--nameTag--6440f";
export var profilePic = "styles-module--profile-pic--c7a2a";
export var providerProfileMain = "styles-module--providerProfileMain--ae7c8";
export var recommended = "styles-module--recommended--e3ea7";
export var rightCard1 = "styles-module--rightCard1--3b3da";
export var rightCard2 = "styles-module--rightCard2--11cf9";
export var titleTxt = "styles-module--titleTxt--77e92";
export var uploadVideo = "styles-module--uploadVideo--08bf9";
export var videoUpload = "styles-module--videoUpload--fd524";