import React, { useEffect, useRef } from "react"
import * as styles from "./styles.module.scss"
import { Button, Container } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import avatar from "../../../assets/images/user.png"
import logo from "../../../assets/images/galeahealth_logo.png"
import plus from "../../../assets/images/plus.png"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"
import axios from "axios"
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop"
import { canvasPreview } from "./canvasPreview"
import { useDebounceEffect } from "./debound"

import "react-image-crop/dist/ReactCrop.css"
import SpinLoad from "../../shared/Spinner"

const PhotosVideos = () => {
	const [file, setFile] = React.useState(null)
	const [vidFile, setVidFile] = React.useState(null)
	const [fileDetail, setFileDetail] = React.useState()
	const [videoFileDetail, setVideoFileDetail] = React.useState()
	const [otherFile, setOtherFile] = React.useState(null)
	const [userFile, setUserFile] = React.useState(null)
	const [userID, setUserId] = React.useState("")
	const [userToken, setUserToken] = React.useState("")
	const [userData, setUserData] = React.useState({})
	const [imgSrc, setImgSrc] = React.useState("")
	const [imgUrl, setImgUrl] = React.useState(null)
	const [otherUrl, setOtherUrl] = React.useState(null)
	const [isImg, setIsImg] = React.useState(null)
	const [crop, setCrop] = React.useState()
	const [done, setDone] = React.useState(0)
	const [delSucces, setDelSuccess] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const previewCanvasRef = useRef(null)
	const imgRef = React.useRef(null)
	const [completedCrop, setCompletedCrop] = React.useState("")
	const [scale, setScale] = React.useState(1)
	const [rotate, setRotate] = React.useState(0)
	const [aspect, setAspect] = React.useState(16 / 9)
	const firstUpdate = useRef(true)

	//After Upload
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		const update = {
			image_url: imgUrl,
		}

		axios
			.put("https://api.galeahealth.com/app/user", update, {
				headers: {
					Authorization: userToken,
					// contentType: 'multipart/form-data',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
					setDone(done + 1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [imgUrl])

	useEffect(() => {
		// const userdata = localStorage.getItem("userData")
		// setUserData(JSON.parse(userdata))

		const userid = localStorage.getItem("userId")
		if (userid) {
			setUserId(userid)
		}
		const usertoken = localStorage.getItem("token")
		if (usertoken) {
			setUserToken(usertoken)
		}
	}, [])

	useEffect(() => {
		setLoading(true)
		const id = localStorage.getItem("userId")
		const token = localStorage.getItem("token")
		// setUserToken(token)
		axios
			.get(`https://api.galeahealth.com/app/user/${id}`, {
				headers: {
					Authorization: token,
				},
			})
			.then((res) => {
				console.log(res.data)
				setUserData(res.data)
				setLoading(false)
				// const userData = res.data
				// localStorage.setItem("userData", JSON.stringify(userData))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [done])

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				//We use canvasPreview as it's much faster than imgPreview.
				await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
			}
		},
		100,
		[completedCrop, scale, rotate]
	)

	useEffect(() => {
		const id = localStorage.getItem("userId")
		// console.log(id)
		axios
			.get(`https://api.galeahealth.com/profile/user-image?user_id=${id}`, {})
			.then((res) => {
				setUserFile(res.data)
				console.log("Get user file ", res.data)
				// toast.success('Get user file successfully')
			})
			.catch((err) => {
				console.log(err)
			})
	}, [delSucces, done])

	const handleImg = () => {
		console.log(file)

		const payloadImg = {
			contentType: "",
			fileName: file?.name,
		}
		console.log(payloadImg)

		axios
			.post("https://api.galeahealth.com/advocate/upload/image", payloadImg, {
				headers: {
					Authorization: userToken,

					"Content-Type": "application/json",
				},
			})

			.then(async (res) => {
				if (res.status === 200) {
					console.log("file here", fileDetail)
					await fetch(res.data.uploadUrl, {
						headers: {
							// "Content-Type": fileDetail.type,
							"Content-Type": "",
						},
						body: fileDetail[0],
						method: "PUT",
					})
					setImgUrl(res.data.fileLink)
					toast.success("Uploaded successfully")
					setIsImg(false)
					setDone(done + 1)
				}
			})
			.catch((err) => {
				console.log("ERROR-->", err)
				console.log(err)
			})
	}

	const removrImg = () => {
		const payloadImg = {
			image_url: null,
		}
		console.log(payloadImg)
		axios
			.put("https://api.galeahealth.com/app/user", payloadImg, {
				headers: {
					Authorization: userToken,
					// "Content-Type": "multipart/form-data",
					// contentType: 'application/json',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
					toast.success("Removed successfully")
					setImgUrl(null)
					setDone(done + 1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const removeVid = () => {
		const payloadImg = {
			video_url: null,
		}
		axios
			.put("https://api.galeahealth.com/app/user", payloadImg, {
				headers: {
					Authorization: userToken,
					// "Content-Type": "multipart/form-data",
					// contentType: 'application/json',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
					toast.success("Removed successfully")
					setDone(done + 1)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	// const handleVid = async () => {
	useEffect(() => {
		if (otherFile) {
			const payloadOther = {
				contentType: "",
				fileName: otherFile?.name,
			}
			axios
				.post("https://api.galeahealth.com/advocate/upload/image", payloadOther, {
					headers: {
						Authorization: userToken,
						// contentType: "multipart/form-data",
						// contentType: 'application/json',
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					if (res.status === 200) {
						console.log(res.data);
						await fetch(res.data.uploadUrl, {
							headers: {
								// "Content-Type": fileDetail.type,
								"Content-Type": "",
							},
							body: otherFile,
							method: "PUT",
						})
						setOtherUrl(res.data.fileLink)
						const update = {
							url: res.data.fileLink,
						}

						axios
							.post("https://api.galeahealth.com/profile/user-image", update, {
								headers: {
									Authorization: userToken,
									// contentType: 'multipart/form-data',
									"Content-Type": "application/json",
								},
							})
							.then((res) => {
								if (res.status === 200) {
									console.log(res.data)
									setDone(done + 1)
								}
							})
							.catch((err) => {
								console.log(err)
							})
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [otherFile])

	useEffect(() => {
		console.log(vidFile)
		if (vidFile) {
			const payloadVid = {
				contentType: vidFile?.type,
				fileName: vidFile?.name,
			}
			console.log(payloadVid)
			axios
				.post("https://api.galeahealth.com/advocate/upload/image", payloadVid, {
					headers: {
						Authorization: userToken,
						// contentType: "multipart/form-data",
						// contentType: 'application/json',
						"Content-Type": "application/json",
					},
				})
				.then(async (res) => {
					if (res.status === 200) {
						console.log("API_RESPONSE-->", res.data)

						await fetch(res.data.uploadUrl, {
							headers: {
								// "Content-Type": fileDetail.type,
								"Content-Type": videoFileDetail[0].type,
							},
							body: videoFileDetail[0],
							method: "PUT",
						})
						const update = {
							video_url: res.data.fileLink,
						}

						axios
							.put("https://api.galeahealth.com/app/user", update, {
								headers: {
									Authorization: userToken,
									// contentType: 'multipart/form-data',
									"Content-Type": "application/json",
								},
							})
							.then((res) => {
								if (res.status === 200) {
									console.log(res.data)
									setDone(done + 1)
									toast.success("Video Uploaded successfully")
								}
							})
							.catch((err) => {
								console.log(err)
							})
					}
				})
				.catch((err) => {
					toast.error("Video Upload failed")
				})
		}
	}, [vidFile])

	// This is to demonstate how to make and center a % aspect crop
	// which is a bit trickier so we use some helper functions.
	function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 90,
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		)
	}

	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}
	}

	const handleCrop = (e) => {
		setIsImg(true)
		// setSrc(URL.createObjectURL(file));
		if (e.target.files && e.target.files.length > 0) {
			// setCrop(undefined) // Makes crop preview update between images.
			const reader = new FileReader()
			// console.log(e.target.files[0]);
			console.log(e.target.file)
			console.log(e.target.files)
			setFileDetail(e.target.files)
			reader.addEventListener("load", () => setImgSrc(reader.result.toString() || ""))
			reader.readAsDataURL(e.target.files[0])
		}
	}
	const convertToImg = (c) => {
		setCompletedCrop(c)
		//convert base64 to image
		const base64 = imgSrc.split(",")[1]
		const image = new Image()
		image.src = imgSrc

		image.onload = () => {
			const canvas = document.createElement("canvas")
			canvas.width = image.width
			canvas.height = image.height
			const ctx = canvas.getContext("2d")
			ctx.drawImage(image, 0, 0)
			// const dataURL = canvas.toDataURL("image/jpeg")
			const dataURL = canvas.toDataURL("image/png")
			// const blob = dataURLtoBlob(dataURL)
			// const file = new File([dataURL], "image.jpg", { type: "image/jpeg" })
			const file = new File([dataURL], fileDetail[0].name, { type: fileDetail[0].type })
			console.log(file)
			setFile(file)
		}
	}

	const otherImgDel = (id) => {
		axios
			.delete(`https://api.galeahealth.com/profile/user-image/${id}`, {
				headers: {
					Authorization: userToken,
					// "Content-Type": "multipart/form-data",
					// contentType: 'application/json',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					toast.success("Removed successfully")
					setDelSuccess(true)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<>
			{loading ? (
				<div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "75%" }}>
					<SpinLoad />
				</div>
			) : (
				<div className={styles.providerProfileMain}>
					<div>
						<Container className="">
							<div className="row pt-5">
								<div className="col-md-12 col-lg-4 pb-3">
									<LeftCardProfile id={userData.id} img={userData.image_url} name={userData.name} />
								</div>
								{/*//Next section*/}
								<div className="col-md-12 col-lg-8">
									<div className={styles.rightCard1}>
										<div className={styles.account}>
											<h4 className="text-center">Step 3: Media</h4>
											<p className="text-center">Please add images and a video to complete your profile.</p>
										</div>
									</div>

									<div className="row">
										<div className="col-md-4 mt-5">
											<div className={`${styles.add} ${styles.contactInfo}`}>
												<h3>Profile Headshot*</h3>
											</div>
											<div className={styles.rightCard1}>
												<div className="text-center">
													<div className={styles.profilePic}>
														<img alt="img" src={userData.image_url || avatar} />
														<label className="mt-3">
															<input type="file" onChange={(e) => handleCrop(e)} name="image" accept=".jpg,.png,.gif" />
															<span className={styles.clickBtn}>Upload Image</span>
														</label>
														<p className={styles.recommended}>Recommended headshot size is 500x500px.</p>
														<button onClick={removrImg} className="btn btn-outline-danger btn-sm remove-btn ">
															{" "}
															Remove Image
														</button>
													</div>
												</div>
											</div>
										</div>
										{isImg && (
											<div className="col-md-6 mt-5">
												<div className={`${styles.add} ${styles.contactInfo}`}>
													<h3>Crop Image</h3>
												</div>
												<div className={styles.rightCard1}>
													<div className="text-center">
														<div className="">
															<ReactCrop
																crop={crop}
																onChange={(_, percentCrop) => setCrop(percentCrop)}
																onComplete={(c) => convertToImg(c)}
																aspect={aspect}
															>
																<img
																	ref={imgRef}
																	alt="Crop me"
																	src={imgSrc}
																	style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
																	onLoad={onImageLoad}
																/>
															</ReactCrop>
															<label className="mt-3">
																<span onClick={handleImg} className={styles.clickBtn}>
																	Submit Image
																</span>
															</label>
														</div>
													</div>
												</div>
												<div>
													{/*To show cropped image*/}
													{/*{Boolean(completedCrop) && (*/}
													{/*	<canvas*/}
													{/*		ref={previewCanvasRef}*/}
													{/*		style={{*/}
													{/*			border: '1px solid black',*/}
													{/*			objectFit: 'contain',*/}
													{/*			width: completedCrop.width,*/}
													{/*			height: completedCrop.height,*/}
													{/*		}}*/}
													{/*	/>*/}
													{/*)}*/}
												</div>
											</div>
										)}
										<div className="col-md-4 mt-5">
											<div className={`${styles.add} ${styles.contactInfo}`}>
												<h3>Your listing card</h3>
											</div>
											<div className={styles.flipCard}>
												<div className={styles.flipCardInner}>
													<div className={styles.flipCardFront}>
														<div className="card-img-top" style={{ marginBottom: "-15px" }}>
															<img
																alt="img"
																style={{ maxWidth: "100%", minWidth: "100%", padding: "0px" }}
																src={userData.image_url || logo}
															/>
														</div>
														<div className={styles.nameTag}>
															<p className={styles.titleTxt}>{userData.name} </p>
															<p style={{ color: "#0078b3" }}>
																{userData?.provider_types ? userData?.provider_types[0]?.title : <span>Loading</span>}
															</p>
														</div>
													</div>
													<div className={`${styles.flipCardBack} ${styles.dFlex} ${styles.flexColumn}`}>
														<p>
															<span
																style={{
																	display: "inline-block",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	whiteSpace: "nowrap",
																	width: "100%",
																}}
															></span>
														</p>
														<div className="button-container mt-auto" style={{ width: "100%" }}>
															<Button className={styles.leftCardBtn} style={{ width: "100%" }}>
																View Profile
															</Button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*	Profile Video*/}
									<div className="row">
										<div className={`${styles.add} ${styles.contactInfo}`}>
											<h3 className="mt-5">Profile Video</h3>
										</div>
										<div className="list-item ">
											<div className="row">
												<div className="col-md-8">
													<div className={styles.rightCard1}>
														<div className="list-item pt-0 p-4">
															<div className="row">
																<div className="col-md-12 col-lg-6">
																	<div className={styles.uploadVideo}>
																		<h4>You can upload your own video of you or your team.</h4>
																		<p>
																			{" "}
																			This will give athletes and potential clients a better understanding of you and
																			encourage collaboration.{" "}
																		</p>
																		<div className="text-center">
																			<label htmlFor="thefile" className="uploadBtnWrap">
																				<input
																					type="file"
																					onChange={(e) => {
																						setVidFile(e.target.files[0])
																						setVideoFileDetail(e.target.files)
																					}}
																					id="thefile"
																					accept=".mp4"
																				/>
																				<span className={styles.clickBtn}>Upload Video</span>
																			</label>
																		</div>
																		<div className="text-center">
																			<p className="mt-3 recommended">
																				{" "}
																				Recommended size is no more 350Mb. Format is MP4 only.{" "}
																			</p>
																			<button
																				onClick={removeVid}
																				className="btn btn-outline-danger btn-sm remove-btn"
																				disabled=""
																			>
																				Remove Video
																			</button>
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-6">
																	<video controls className={styles.videoUpload}>
																		{/*<source src="https://learn.shayhowe.com/assets/misc/courses/html-css/adding-media/earth.ogv" type="video/ogg"/>*/}
																		<source
																			src={
																				userData.video_url ||
																				"https://learn.shayhowe.com/assets/misc/courses/html-css/adding-media/earth.mp4"
																			}
																			type="video/mp4"
																		/>
																	</video>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										{/*Last Section*/}
										<div className={`${styles.add} ${styles.contactInfo}`}>
											<h3 className="mt-5">Other Profile Images</h3>
										</div>
										<div className="list-item pb-5">
											<div className="row">
												<div className="col-md-12">
													<div className={styles.rightCard1}>
														<div className="row">
															{userFile &&
																userFile.map((item, index) => (
																	<div className="col-md-4">
																		<img
																			onClick={() => otherImgDel(item.id)}
																			src={item.url}
																			style={{ width: "100px", height: "110px" }}
																		/>
																		<div>
																			<a style={{ cursor: "pointer" }} onClick={() => otherImgDel(item.id)}>
																				<svg
																					onClick={() => otherImgDel(item.id)}
																					style={{ height: "17px" }}
																					className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root  css-1shn170 "
																					focusable="false"
																					aria-hidden="true"
																					viewBox="0 0 24 24"
																					data-testid="DeleteTwoToneIcon"
																					tabIndex="-1"
																					title="DeleteTwoTone"
																				>
																					<path d="M8 9h8v10H8z" opacity=".3"></path>
																					<path d="m15.5 4-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path>
																				</svg>{" "}
																				Remove
																			</a>
																		</div>
																	</div>
																))}
															<label className="mt-3">
																<input
																	type="file"
																	onChange={(e) => setOtherFile(e.target.files[0])}
																	name="image"
																	accept=".jpg,.png,.gif"
																/>
																<span className={styles.genderText}>
																	<img src={plus} className="mt-0 pointer" />
																	<span> Add new image</span>
																</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Container>
						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						{/* Same as */}
						<ToastContainer />
					</div>
				</div>
			)}
		</>
	)
}

export default PhotosVideos
